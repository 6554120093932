// src/App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import LoginPage from './pages/LoginPage';
import ProcessingPage from './pages/ProcessingPage';
import OTPPage from './pages/OTPPage';
import PolicyCoverageAnalysis from './pages/PolicyCoverageAssessment';
import HomeScreen from './pages/HomeScreen';
import ClaimAssessment from './pages/ClaimAssessment';
import ClaimEmail from './pages/ClaimEmail';
import PolicyCoverage from './pages/PolicyCoverage';
import ChatWindow from './pages/chat/ChatWindow';
import ChatPage from './pages/chat/ChatPage';
import ChatLoginPage from './pages/chat/ChatLoginPage';

function App() {
  const [user, setUser] = useState(null);

  const handleLogin = (name, phone) => {
    // Logic to handle login (you can add validation here)
    setUser({ name, phone });
  };

  return (
    <Router>
  <Routes>
    <Route path="/" element={<LoginPage />} />
    <Route path="/process" element={<ProcessingPage />} />
    <Route path="/otp" element={<OTPPage />} />
    <Route path="/policy-assessment" element={<PolicyCoverageAnalysis />} />
    <Route path="/home-screen" element={<HomeScreen />} />
    <Route path="/claim-assessment" element={<ClaimAssessment />} />
    <Route path="/claim-email" element={<ClaimEmail />} />
    <Route path="/policy-coverage" element={<PolicyCoverage />} />
    <Route path="/chat-window" element={<ChatWindow />} />
    <Route path="/chat-page" element={<ChatPage />} />
    <Route path="/chat-login" element={<ChatLoginPage />} />
    <Route path="*" element={<Navigate to="/" />} />
  </Routes>
</Router>

  );
}

export default App;
